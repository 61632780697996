<template>
  <div class="content">
    <el-form :model="ruleForm" status-icon :rules="rules" ref="ruleForm" label-width="190px" class="demo-ruleForm">
      <div style="padding: 10px 20px; background-color: #C0C4CC; margin-bottom: 15px;">
        基本资料信息
      </div>
      <div style="width: 435px; margin: auto">
        <el-form-item label="企业名称" prop="shop_name">
          <el-input v-model="ruleForm.shop_name"></el-input>
        </el-form-item>
        <el-form-item label="企业账号" prop="uname">
          <el-input v-model="ruleForm.uname"></el-input>
        </el-form-item>
        <el-form-item label="企业等级" prop="level_id">
          <el-select v-model="ruleForm.level_id" placeholder="企业等级">
            <el-option v-for="item in levelList" :key="item.id" :label="item.level_name" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="账户金额" prop="balance_account">
          <el-input disabled v-model="ruleForm.balance_account"></el-input>
        </el-form-item>
        <el-form-item label="销售人员" prop="sales_id">
          <el-select v-model="ruleForm.sales_id" placeholder="请选择销售人员" @change="saleChange">
            <el-option v-for="item in options" :key="item.id" :label="item.username" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="公司电话" prop="company_phone">
          <el-input v-model="ruleForm.company_phone"></el-input>
        </el-form-item>
        <el-form-item label="法人姓名" prop="legal_name">
          <el-input v-model="ruleForm.legal_name"></el-input>
        </el-form-item>
        <el-form-item label="法人身份证号" prop="legal_id">
          <el-input v-model="ruleForm.legal_id"></el-input>
        </el-form-item>
        <el-form-item label="营业执照号" prop="license_num">
          <el-input v-model="ruleForm.license_num"></el-input>
        </el-form-item>
        <el-form-item label="企业地址" prop="company_address">
          <el-input v-model="ruleForm.company_address"></el-input>
        </el-form-item>
        <el-form-item label="电子邮箱" prop="company_email">
          <el-input v-model="ruleForm.company_email" type="email"></el-input>
        </el-form-item>
        <el-form-item label="联系电话" prop="link_phone">
          <el-input v-model="ruleForm.link_phone"></el-input>
        </el-form-item>

        <el-form-item label="营业执照有效期" prop="times">
          <template>
            <el-date-picker @change="timeChange" v-model="ruleForm.times" type="daterange" ref="times" range-separator="至"
              start-placeholder="开始日期" end-placeholder="结束日期" :editable="false">
            </el-date-picker>
          </template>
        </el-form-item>
        <el-form-item label="营业执照" prop="licence_img">
          <el-upload class="site-logo" :action="MixinUploadApi" :show-file-list="false" v-model="ruleForm.licence_img"
            :on-success="(res) => {
              ruleForm.licence_img = res.url;
            }
              " :multiple="false">
            <img v-if="ruleForm.licence_img" :src="ruleForm.licence_img" class="site-logo-img" />
            <i v-else class="el-icon-plus logo-uploader-icon"></i>
          </el-upload>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="submitForm('ruleForm')" size="small">提交
          </el-button>
        </el-form-item>
        <!-- <el-form-item label="新密码" prop="password">
          <template>
            <el-input
              type="password"
              style="position: fixed; bottom: -9999999px"
            ></el-input>
            <el-input
              v-model="ruleForm.password"
              :placeholder="shopId ? '不填则不更改' : ''"
              type="password"
            ></el-input>
          </template>
        </el-form-item>
        <el-form-item label="确认密码" prop="endPwd">
          <el-input v-model="ruleForm.endPwd" type="password"></el-input>
        </el-form-item>
        <el-form-item label="账户启用状态">
          <template>
            <el-switch
              @change="switchChange"
              v-model="dis"
              active-color="#13ce66"
              inactive-color="#ff4949"
            >
            </el-switch>
          </template>
        </el-form-item> -->
        <!-- 3.0.3功能新加智能运营，楼层装修2。0-->
      </div>
      <!-- <div style="padding: 10px 20px; background-color: #C0C4CC; margin-bottom: 15px;">
        企业特定权限开关
      </div>
      <div style="width: 435px; margin: auto">
        <el-form-item label="是否拥有智能运营功能">
          <el-radio v-model="ruleForm.auto_up_down_status" :label="0">关闭</el-radio>
          <el-radio v-model="ruleForm.auto_up_down_status" :label="1">开启</el-radio>
        </el-form-item>
        <el-form-item label="楼层装修2.0">
          <el-radio v-model="ruleForm.storey_decorate20_status" :label="0">关闭</el-radio>
          <el-radio v-model="ruleForm.storey_decorate20_status" :label="1">开启</el-radio>
        </el-form-item>
        <el-form-item label="企业自有商品">
          <el-radio v-model="ruleForm.has_self_goods" :label="0">关闭</el-radio>
          <el-radio v-model="ruleForm.has_self_goods" :label="1">开启</el-radio>
        </el-form-item>
      </div> -->
    </el-form>
  </div>
</template>
<script>
import * as API_shop from "@/api/shop";
import { UE } from "@/components";
import { validateEmail, validateUname } from "@/utils/validate";

export default {
  name: "enterpriseAdd",
  components: {
    UE,
  },
  data () {
    const validateNewPwd = (rule, value, callback) => {
      console.log(2222);
      if (value !== this.ruleForm.password) {
        callback(new Error("密码不一致"));
      } else {
        callback();
      }
    };

    const validatePwd = (rule, value, callback) => {
      if (!validateUname(value)) {
        callback(new Error('请输入密码, 6~20位纯数字或纯字母或数字+字母组成。字母区分大小写'))
      } else {
        callback()
      }
    }

    const shop_nameReg = /^[\u4e00-\u9fa5]*$/;
    const shop_nameValidate = (rule, value, callback) => {
      if (!value) {
        callback(new Error("请输入不少于2个汉字的企业名称"));
      }
      if (!shop_nameReg.test(value)) {
        callback(new Error("请输入不少于2个汉字的企业名称"));
      }
      if (value.length < 2) {
        callback(new Error("请输入不少于2个汉字的企业名称"));
      }

      callback();
    };

    const mobileValidator = (rule, value, callback) => {
      if (!value) {
        callback(new Error("请输入企业账号，6~20位纯数字或纯字母或数字+字母组成。字母区分大小写"));
      }
      if (!validateUname(value)) {
        callback(new Error("请输入企业账号，6~20位纯数字或纯字母或数字+字母组成。字母区分大小写"));
      }
      callback();
    };
    const legal_nameValidate = (rule, value, callback) => {
      if (!value) {
        callback(new Error("请输入法人姓名"));
      }
      if (!shop_nameReg.test(value)) {
        callback(new Error("请输入正确的法人姓名"));
      }
      if (value.length < 2 || value.length > 20) {
        callback(new Error("法人姓名长度不能小于2位或者大于20位"));
      }
      callback();
    };
    const legal_idReg = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/
    const legal_idValidate = (rule, value, callback) => {
      if (!value) {
        callback(new Error("请输入法人身份证号"));
      }
      if (!legal_idReg.test(value)) {
        callback(new Error("请输入正确的身份证号"));
      }
      callback();
    };
    const license_numReg = /(^(?:(?![IOZSV])[\dA-Z]){2}\d{6}(?:(?![IOZSV])[\dA-Z]){10}$)|(^\d{15}$)/
    const license_numValidate = (rule, value, callback) => {
      if (!value) {
        callback(new Error("请输入营业执照号"));
      }
      if (!license_numReg.test(value)) {
        callback(new Error("请输入正确的营业执照号"));
      }
      callback();
    };

    const company_emailValidate = (rule, value, callback) => {
      if (!value) {
        callback(new Error("请输入电子邮箱"));
      }
      if (!validateEmail(value)) {
        callback(new Error("请输入正确的电子邮箱"));
      }
      callback();
    }
    return {
      times: [],
      levelList: [],
      value: "",
      options: [],
      checkList: ["选中且禁用"],
      ruleForm: {
        licence_img: "",
        shop_name: "",
        uname: "",
        level_id: "",
        balance_account: "",
        salesperson: "",
        company_address: "",
        company_phone: "",
        legal_name: "",
        license_num: "",
        company_email: "",
        link_phone: "",
        legal_id: "",
        licence_start: "",
        licence_end: "",
        // password:"",
        disabled: 0,
        // endPwd:"",
        sales_id: "",
        times: [],
        // operation: false,
        storey_decorate20_status: 0,
        has_self_goods: 1,
        self_goods_count: 0,
        self_order_status: 0,
        self_order_refund_status: 0,
        shop_disable: 0,
        auto_up_down_status: 0
      },
      shopId: "",
      imgList: [],
      dis: "",
      rules: {
        shop_name: [
          // { required: true, validator: shop_nameValidate, trigger: "blur" },
          { required: true, message: '请输入企业名称', trigger: "blur" }
        ],
        uname: [
          { required: true, validator: mobileValidator, trigger: "blur" },
        ],
        sales_id: [
          { required: true, message: "请选择销售人员", trigger: "change" },
        ],
        company_phone: [
          { required: true, message: "请输入公司电话", trigger: "blur" },
        ],
        legal_name: [
          { required: true, validator: legal_nameValidate, trigger: "blur" },
        ],
        legal_id: [
          { required: true, validator: legal_idValidate, trigger: "blur" },
        ],
        license_num: [
          { required: true, validator: license_numValidate, trigger: "blur" },
        ],
        company_address: [
          { required: true, message: "请输入企业地址", trigger: "blur" },
        ],
        company_email: [
          { required: true, validator: company_emailValidate, trigger: "blur" },
          // { required: true, message: "请输入电子邮箱", trigger: "blur" },
        ],
        link_phone: [
          { required: true, message: "请输入联系电话", trigger: "blur" },
        ],
        level_id: [
          { required: true, message: "请选择企业等级", trigger: "change" },
        ],
        licence_img: [
          { required: true, message: "请上传营业执照", trigger: "change" },
        ],
        times: [
          {
            type: "array",
            required: true,
            message: "请选择营业执照有效期",
            trigger: "change,blur",
          },
        ],

        password: [
          { required: true, message: "请输入密码", trigger: "blur" },
          { required: true, validator: validatePwd, trigger: "blur" },
          // {
          //   min: 6,
          //   max: 20,
          //   message: "长度在 6 到 20 个字符",
          // },
        ],
        endPwd: [
          { required: true, message: "请输入密码", trigger: "blur" },
          { validator: validateNewPwd, trigger: "blur" },
          {
            min: 6,
            max: 20,
            message: "长度在 6 到 20 个字符",
          },
        ],
      },
      //企业等级列表
      leveloptions: [],
    };
  },
  mounted () {
    this.shopId = this.$route.query.shopid || "";
    if (this.shopId) {
      console.log(this.rules)
      this.rules.password[0].required = false;
      this.rules.endPwd[0].required = false;
      this.getDetail();
    }
    this.getPerList();
    this.GET_levelList();
  },
  activated () {
    this.getPerList();
    this.GET_levelList();
  },
  methods: {
    getDetail () {
      API_shop.getDetail(this.shopId).then((res) => {
        for (let k in this.ruleForm) {
          this.ruleForm[k] = res[k];
        }
        if (!this.ruleForm.has_self_goods) this.ruleForm.has_self_goods = 0
        let arr = [];
        arr.push(new Date(this.ruleForm.licence_start * 1000));
        arr.push(new Date(this.ruleForm.licence_end * 1000));
        this.dis = this.ruleForm.shop_disable === 'OPEN'
        console.log(this.ruleForm, this.dis)
        this.ruleForm.times = JSON.parse(JSON.stringify(arr));
      });
    },
    saleChange () {
      for (let i = 0; i < this.options.length; i++) {
        if (this.options[i].id === this.ruleForm.sales_id) {
          this.ruleForm.salesperson = this.options[i].username;
        }
      }
    },
    getPerList () {
      API_shop.getPerList().then((res) => {
        this.options = res;
      });
    },
    GET_levelList () {
      API_shop.getLevelList().then((res) => {
        this.levelList = res;
      });
    },
    timeChange () {
      if (
        this.ruleForm.times &&
        this.ruleForm.times.length &&
        this.ruleForm.times.length > 0
      ) {
        this.ruleForm.licence_start =
          new Date(this.ruleForm.times[0]).getTime() / 1000;
        this.ruleForm.licence_end =
          new Date(this.ruleForm.times[1]).getTime() / 1000;
      }
    },
    switchChange () {
      if (this.dis) {
        this.ruleForm.shop_disable = "OPEN";
        this.ruleForm.disabled = 0
      } else {
        this.ruleForm.shop_disable = "CLOSED";
        this.ruleForm.disabled = -1
      }
    },
    close () {
      this.shows = false;
    },
    submitForm (formName) {
      console.log(this.ruleForm, "表单");
      if (this.shopId) {
        this.ruleForm.shop_id = this.shopId;
        this.$refs[formName].validate((valid) => {
          if (valid) {
            if (this.ruleForm.has_self_goods === 0) {
              if (this.ruleForm.self_goods_count !== 0 || this.ruleForm.self_order_refund_status === 0 || this.ruleForm.self_order_status === 0) {
                this.$message.error('当前企业已经添加了自有商品不可关闭')
                return false;
              }
            }
            API_shop.editCompany(this.shopId, this.ruleForm).then((res) => {
              this.$router.push({
                path: "enterp/enterplist",
              });
            });
          }
        })
      } else {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            API_shop.addCompany(this.ruleForm).then((res) => {
              this.$message({
                showClose: true,
                message: "添加成功",
                type: "success",
              });
              this.$router.push({
                path: "enterp/enterplist",
              });
            });
          }
        });
      }
    },
  }
};
</script>

<style lang="scss" scoped>
::v-deep .el-form-item {
  margin-bottom: 30px;
}

::v-deep .el-input__inner {
  width: 250px;
}

::v-deep .site-logo .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;

  &:hover {
    border-color: #409eff;
  }
}

::v-deep .logo-uploader-icon {
  font-size: 20px;
  color: #8c939d;
  width: 120px;
  height: 120px;
  line-height: 120px;
  text-align: center;
}

.site-logo-img {
  width: 120px;
  height: 120px;
  display: block;
}

::v-deep .el-textarea__inner {
  width: 250px;
}

::v-deep .el-range-separator {
  width: 25px;
}

.demo-ruleForm {
  vertical-align: middle;
}

.content {
  background-color: white;
  padding-bottom: 20px;
  padding-top: 20px;
}
</style>
